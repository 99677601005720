<template>
  <!-- <div class="outer-feed-entry">
    <div class="inner-feed-entry" id="feed-list" >
        <post-view v-for="postFeed in feedPosts" :key="postFeed._id" :post="postFeed"
          :viewonly="false"/>
    </div>
  </div> -->
  <div class="inner-post-entry">
    <feedCard v-for="entry in feedPosts" :key="entry._id" :elem="entry" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import feedCard from '@/components/UniversalComponents/Search/Results/specificCards/feedCard.vue';

export default {
  name: 'myFeed',
  components: {
    feedCard,
  },
  data() {
    return {
      postText: '',
    };
  },
  computed: {
    ...mapState('feed/myFeed', ['feedPosts']),
  },

};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .inner-post-entry{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    .elem-feed{
      width: 100%;
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      margin-bottom: 1.5vh;
      &:nth-child(2n){
        margin-right: 0;
      }
      .head-feed{
        display:flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: calc(100% - 2vh);
        padding: 1vh;
        border-bottom: 0.4vh solid $new_bg_grey;
        .pic-name{
          display:flex;
          align-items: center;
          .profile-pic{
            height: 5vh;
            width: 5vh;
            background-size: cover;
            background-position: 50% 50%;
            background-color: $logo_color;
            border-radius: 50%;
            border: 0.1vh solid $logo_color;
            overflow: hidden;
          }
          .profile-user{
            margin-left: 1.5vh;
            display: flex;
            flex-direction: column;
            .name-user{
              font-size: 2vh;
              font-family: 'MontserratBold';
            }
            .time-post{
              margin-top: 0.5vh;
              font-size: 1.2vh;
              color: $border_darkgray;
            }
          }
        }
        .btn-green{
        }
      }
      .content-feed{
        padding: 1vh;
        flex: 1;
        .excerp-post{
          margin-bottom: 1vh;
          max-height: 150px;
          overflow: hidden;
        }
        .visual-feed{
          width: 100%;
          font-size: 0;
          overflow: hidden;
          img{
            width: 100%;
          }
        }
      }
      .footer-feed{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1vh;
        .left-footer{
          display: flex;
          .elem-footer{
            .picto{
              width: 2vh;
              img{
                width: 100%;
              }
            }
          }
        }
        .right-footer{
          display: flex;
        }
      }
    }
  }
</style>
