<template>
  <div class="pic-name radius box-shadow">
    <div class="profile-pic"
      :class="posterPicture ? '' : 'empty'"
      :style=" posterPicture !== '' ? `background-image:url(${posterPicture})` : ''"
    >
    </div>
    <div class="profile-user">
      <!-- {{post.owner}} -->
      <div class="name-user"
        v-if="account.accountType === 'Talent'"
      >
          {{ownerString}}
      </div>
      <div class="name-user"
      >
          {{ownerString}}
      </div>
      <div class="options">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FollowUser',
  props: ['account'],
  computed: {
    ownerString() {
      if (this.account.account.name.firstName) {
        return `${this.account.account.name.firstName} ${this.account.account.name.lastName}`;
      }
      return this.account.account.name;
    },

    posterPicture() {
      if (this.account.account.profilePicture) {
        return this.account.account.profilePicture;
      }
      if (this.account.account.companyPicture) {
        return this.account.account.companyPicture;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";

.pic-name{
  padding: 1vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1.5vh;
  width: calc(100% - 2vh);
  &:last-child{
    margin-bottom: 0;
  }
  .profile-pic{
    height: 4vh;
    width: 4vh;
    border-radius: 50%;
    background-color: $logo_color_dark;
    background-color: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    &.empty{
      background-color: $logo_color_dark;
    }
  }
  .profile-user{
    width: calc(100% - 5vh);
    margin-left: 1vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .name-user{
      font-size: $fnt-18;
    }
    .time-post{
      font-size: $fnt-12;
      color: #4c4c4c;
    }
  }
}

</style>
