<template>
  <div class="follow-modal">
    <div class="green-circle-button close-button" @click="closeModal">
      <span>X</span>
    </div>
    <!-- <button @click="changeView">{{viewFollowing? 'Following' : 'Followers'}}</button> -->
    <div class="follow-list following">
      <div class="title-page">
        Following
      </div>
      <div class="inner-list" id="list-following">
        <account v-for="acct in following" :key="acct._id" :account="acct">
          <div class="btn-green reverse radius-small small"
            @click="() => unfollow(acct._id)"
          >
            Unfollow
          </div>
        </account>
      </div>
    </div>
    <div class="follow-divider">
    </div>
    <div class="follow-list followers">
      <div class="title-page">
        Followers
      </div>
      <div class="inner-list" id="list-followers">
        <account v-for="acct in followers" :key="acct._id" :account="acct">
          <follow-button :type="acct.accountType" :accountId="acct.account._id"/>
        </account>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import account from '@/components/UniversalComponents/Feed/following/_account.vue';
import FollowButton from '@/components/UniversalComponents/Feed/following/followButton.vue';

export default {
  name: 'FollowModal',
  components: { account, FollowButton },
  data() {
    return {
      viewFollowing: true,
    };
  },
  computed: {
    following() {
      return this.$store.state.feed.followList.following;
    },
    followers() {
      return this.$store.state.feed.followList.followers;
    },

  },
  mounted() {
    this.$store.dispatch('feed/followList/getFollowing');

    const psFollowing = new PerfectScrollbar('#list-following', {
      wheelPropagation: false,
      wheelSpeed: 1,
    });
    psFollowing.update();

    const psFollowers = new PerfectScrollbar('#list-followers', {
      wheelPropagation: false,
      wheelSpeed: 1,
    });
    psFollowers.update();
  },
  methods: {
    changeView() {
      this.viewFollowing = !this.viewFollowing;
    },
    unfollow(id) {
      this.$store.dispatch('feed/followList/unfollow', id);
    },
    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'following-modal');
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.follow-modal{
  position: relative;
  padding: 2vh;
  width: calc(100% - 4vh);
  display: flex;
  flex-wrap: wrap;
  .follow-list{
    max-height: 58vh;
    width: calc(50% - 4vh);
    &:first-child{
      margin-right: 2vh;
    }
  }
}
.follow-divider{
  width: 3vh;
  border-left: 1px $logo_color_dark solid;
}

.inner-list{
  position: relative;
  padding: 0.5vh;
  height: 54vh;
}

</style>
