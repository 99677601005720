import FollowModal from '@/components/UniversalComponents/Feed/following/followModal.vue';

export default {
  methods: {
    showFollowModal() {
      this.$modal.show(
        FollowModal,
        {
        },
        {
          name: 'following-modal',
          width: '60%',
          height: '600px',
          scrollable: true,
          adaptive: true,
          classes: ['overflow'],
        },
      );
    },
  },
};
