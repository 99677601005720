<template>
  <div class="new-post-cont radius box-shadow">
    <div class="cont-collapse">
      <div class="cont-classic" @click="openText">
        <h4>{{ $t("createpost") }}</h4>
        <div class="cont-plus box-shadow">
          <div class="cont-cross">
            <div class="barcross verti"></div>
            <div class="barcross hori"></div>
          </div>
        </div>
      </div>
      <div class="cont-other">
        <div
          class="iconpost icon-picture box-shadow"
          v-if="!postAttachment"
          @click="openAttachMedia('Image')"
          v-tooltip.top-center="{
            content: $t('addpicture'),
            delay: {
              show: 0,
              hide: 0,
            }
          }"
        >
          <img class="picto photo" :src="require('@/assets/pictos/picto_photo_white.svg')">
          <img class="picto photo hover"
            :src="require('@/assets/pictos/picto_photo_purple.svg')"
          >
        </div>
        <div
          class="iconpost icon-video box-shadow"
          v-if="!postAttachment"
          @click="openAttachMedia('Video')"
          v-tooltip.top-center="{
            content: $t('addvideo'),
            delay: {
              show: 0,
              hide: 0,
            }
          }"
        >
          <img class="picto video" :src="require('@/assets/pictos/picto_video_white.svg')">
          <img class="picto video hover"
            :src="require('@/assets/pictos/picto_video_purple.svg')">
        </div>
        <div
          class="iconpost icon-doc box-shadow"
          v-if="!postAttachment"
          @click="openAttachMedia('Pdf')"
          v-tooltip.top-center="{
            content: $t('addpdf'),
            delay: {
              show: 0,
              hide: 0,
            }
          }"
        >
          <img class="picto doc" :src="require('@/assets/pictos/picto_pdf_white.svg')">
          <img class="picto doc hover"
            :src="require('@/assets/pictos/picto_pdf_purple.svg')">
        </div>
        <div
          class="iconpost icon-doc box-shadow"
          v-if="!postAttachment"
          @click="openAttachMedia('Audio')"
          v-tooltip.top-center="{
            content: $t('addaudio'),
            delay: {
              show: 0,
              hide: 0,
            }
          }"
        >
          <img class="picto doc" :src="require('@/assets/pictos/picto_audio_white.svg')">
          <img class="picto doc hover"
            :src="require('@/assets/pictos/picto_audio_purple.svg')">
        </div>
        <!-- <div class="icon-doc" v-if="!postAttachment" @click="openAttach('Video')">
          <img class="picto doc" :src="require('@/assets/pictos/picto_file_darkpurple.svg')">
          <img class="picto doc hover"
            :src="require('@/assets/pictos/picto_file_purple.svg')">
        </div> -->
      </div>
    </div>
    <div class="cont-open" v-if="!collapse">
      <div class="cont-textarea radius boxshadow">
        <div class="attachment" v-if="postAttachment" >
          <img
            v-if="postAttachment.type === 'Image'"
            :src="postAttachment.data"
            :alt="decodeURI(postAttachment.data.split('/')[postAttachment
              .data.split('/').length-1])"
          >
          <video
            v-if="postAttachment.type === 'Video'"
            controls
            class="preview" :src="postAttachment.data">
          </video>
          <audio controls class="doc audio"
            v-else-if="postAttachment.type === 'Audio'"
            :src="postAttachment.data"
          ></audio>
          <embed class="preview pdf-preview"
          v-else-if="postAttachment.type === 'Application' || postAttachment.type === 'Pdf'"
          :src="postAttachment.data" />
          <!-- <media-view :media="postAttachment" /> -->
          <div class="delete-cont" @click="deleteAttachment">

              X

          </div>

        </div>
        <span
          class="textarea"
          role="textbox"
          contenteditable
          @input="onInput"
          @keyup.enter="submitPost">
        </span>

        <div class="cont-btn">
          <div class="cont-count">{{ countCar }} {{ $t("nbcar") }}</div>
          <div class="btn-green radius" @click="submitPost">{{ $t("btn_submit") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import selectMedia from '@/modules/mixins/selectMedia';
// import MediaView from '@/components/UniversalComponents/Media/mediaView.vue';

export default {
  name: 'newPost',
  mixins: [selectMedia],
  data() {
    return {
      postText: '',
      postAttachment: false,
      collapse: true,
      countCar: 0,
    };
  },
  computed: {
    ...mapState('feed/myFeed', ['feedPosts']),
  },
  mounted() {
  },
  methods: {
    submitPost() {
      this.$store.dispatch('feed/createPost', { text: this.postText, attachments: this.postAttachment ? [this.postAttachment] : [] });
      this.postText = '';
      this.collapse = true;
      this.postAttachment = false;
    },
    openText() {
      this.collapse = !this.collapse;
      this.postText = '';
      this.countCar = 0;
    },
    deleteAttachment() {
      this.postAttachment = false;
    },
    openAttachMedia(type) {
      if (this.collapse) {
        this.openText();
      }
      this.openAttach(type, (media) => { this.postAttachment = media; });
    },
    onInput(e) {
      this.postText = e.target.innerText;
      if (this.postText.includes('https://')
        || this.postText.includes('http://')) {
        this.handleURL(this.urlify(this.postText));
      }
      this.countCar = this.postText.length;
    },
    urlify(text) { // get url from string
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, (url) => `${url}`);
    },
    // https://madmixdigital.com/
    handleURL(url) {
      axios.get(url, {}).then((r) => {
        this.getMetaContent(r.data, 'description');
      }).catch((e) => {
        console.error(JSON.stringify(e));
      });
    },
  },

};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .new-post-cont{
    width: calc(100% - 2vh);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 1vh;
    background-color: $green_color;
    margin-bottom: 2vh;
    .cont-collapse{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      // flex-wrap: wrap;
      width: 100%;
      .cont-classic{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        .cont-plus{
          position: relative;
          height: 5vh;
          width: 5vh;
          border-radius: 50%;
          background-color: $green_color;
          transition: all 0.2s;
          margin-left: 2vh;
          .cont-cross{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            .barcross{
              height: 0.4vh;
              width: 1.5vh;
              background-color: $logo_color;
              transition: all 0.2s;
              border-radius: 10px;
              overflow: hidden;
              position: absolute;
              top: 50%;
              left: 50%;
              &.verti{
                transform: translateX(-50%) translateY(-50%) rotate(90deg);
              }
              &.hori{
                transform: translateX(-50%) translateY(-50%);
              }
            }
          }
        }
      }
      .cont-other{
        // width: 18%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        // display: none;
        .iconpost{
          position: relative;
          height: 5vh;
          width: 5vh;
          border-radius: 50%;
          background-color: $logo_color;
          transition: all 0.2s;
          margin-right: 1vh;
          cursor: pointer;
          &:hover{
            background-color: $green_color;
            .picto{
              opacity: 0;
              &.hover{
                opacity: 1;
              }
            }
          }
          .picto{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            &.photo{
              width: 3.5vh;
            }
            &.video{
              width: 3vh;
            }
            &.doc{
              width: 2.5vh;
            }
            &.hover{
              opacity: 0;
            }
          }
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
    .cont-open{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      margin-top: 1.5vh;
      width: 100%;
      .cont-textarea{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        background-color: #ffffff;
        .textarea{
          width: calc(100% - 2.2vh);
          overflow: hidden;
          display: block;
          margin: 0;
          padding: 0;
          border: 0;
          resize: none;
          padding: 1vh 1vh 1vh 1vh;
          margin-bottom: 6.2vh;
          display: flex;
          &:focus{
            outline: none !important;
          }
        }
        .textarea[contenteditable]:empty::before {
          content: "What do you want to share?";
          color: gray;
        }
        .cont-btn{
          position: absolute;
          bottom: 1vh;
          right: 1vh;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          .cont-count{
            color: $border_gray;
            margin-right: 1vh;
          }
          .attachment{
            margin: 1vh;
          }
          .icon-doc{
          position: relative;
          .picto{
            cursor: pointer;
            &.doc{
              width: 2.5vh;
            }
            &.hover{
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          &:hover{
            .picto{
              opacity: 0;
              &.hover{
                opacity: 1;
              }
            }
          }
        }
        }

      }
    }
    &:hover{
      .cont-collapse{
        .cont-classic{
          .cont-plus{
            background-color: $logo_color;
            .cont-cross{
              .barcross{
                background-color: $green_color;
              }
            }
          }
        }
      }
    }
  }

  .attachment{
    width: calc(97% - 2vh);
    margin: 1vh;
    left: 1vh;
    overflow: hidden;
    max-height: 25vh;
    align-self: flex-start;
    align-items: center;
    img{
      width: auto;
      height: 100%;
      align-self: center;
    }
    video{
      width: auto;
      height: 100%;
      align-self: center;

    }

  }
  .delete-cont{
    position: absolute;
    right: 1vh;
    top: 2vh;
    width: unset !important;
    height: unset !important;
    cursor: pointer;
  }

</style>
