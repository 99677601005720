var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-post-cont radius box-shadow"},[_c('div',{staticClass:"cont-collapse"},[_c('div',{staticClass:"cont-classic",on:{"click":_vm.openText}},[_c('h4',[_vm._v(_vm._s(_vm.$t("createpost")))]),_vm._m(0)]),_c('div',{staticClass:"cont-other"},[(!_vm.postAttachment)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
          content: _vm.$t('addpicture'),
          delay: {
            show: 0,
            hide: 0,
          }
        }),expression:"{\n          content: $t('addpicture'),\n          delay: {\n            show: 0,\n            hide: 0,\n          }\n        }",modifiers:{"top-center":true}}],staticClass:"iconpost icon-picture box-shadow",on:{"click":function($event){return _vm.openAttachMedia('Image')}}},[_c('img',{staticClass:"picto photo",attrs:{"src":require('@/assets/pictos/picto_photo_white.svg')}}),_c('img',{staticClass:"picto photo hover",attrs:{"src":require('@/assets/pictos/picto_photo_purple.svg')}})]):_vm._e(),(!_vm.postAttachment)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
          content: _vm.$t('addvideo'),
          delay: {
            show: 0,
            hide: 0,
          }
        }),expression:"{\n          content: $t('addvideo'),\n          delay: {\n            show: 0,\n            hide: 0,\n          }\n        }",modifiers:{"top-center":true}}],staticClass:"iconpost icon-video box-shadow",on:{"click":function($event){return _vm.openAttachMedia('Video')}}},[_c('img',{staticClass:"picto video",attrs:{"src":require('@/assets/pictos/picto_video_white.svg')}}),_c('img',{staticClass:"picto video hover",attrs:{"src":require('@/assets/pictos/picto_video_purple.svg')}})]):_vm._e(),(!_vm.postAttachment)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
          content: _vm.$t('addpdf'),
          delay: {
            show: 0,
            hide: 0,
          }
        }),expression:"{\n          content: $t('addpdf'),\n          delay: {\n            show: 0,\n            hide: 0,\n          }\n        }",modifiers:{"top-center":true}}],staticClass:"iconpost icon-doc box-shadow",on:{"click":function($event){return _vm.openAttachMedia('Pdf')}}},[_c('img',{staticClass:"picto doc",attrs:{"src":require('@/assets/pictos/picto_pdf_white.svg')}}),_c('img',{staticClass:"picto doc hover",attrs:{"src":require('@/assets/pictos/picto_pdf_purple.svg')}})]):_vm._e(),(!_vm.postAttachment)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:({
          content: _vm.$t('addaudio'),
          delay: {
            show: 0,
            hide: 0,
          }
        }),expression:"{\n          content: $t('addaudio'),\n          delay: {\n            show: 0,\n            hide: 0,\n          }\n        }",modifiers:{"top-center":true}}],staticClass:"iconpost icon-doc box-shadow",on:{"click":function($event){return _vm.openAttachMedia('Audio')}}},[_c('img',{staticClass:"picto doc",attrs:{"src":require('@/assets/pictos/picto_audio_white.svg')}}),_c('img',{staticClass:"picto doc hover",attrs:{"src":require('@/assets/pictos/picto_audio_purple.svg')}})]):_vm._e()])]),(!_vm.collapse)?_c('div',{staticClass:"cont-open"},[_c('div',{staticClass:"cont-textarea radius boxshadow"},[(_vm.postAttachment)?_c('div',{staticClass:"attachment"},[(_vm.postAttachment.type === 'Image')?_c('img',{attrs:{"src":_vm.postAttachment.data,"alt":decodeURI(_vm.postAttachment.data.split('/')[_vm.postAttachment
            .data.split('/').length-1])}}):_vm._e(),(_vm.postAttachment.type === 'Video')?_c('video',{staticClass:"preview",attrs:{"controls":"","src":_vm.postAttachment.data}}):(_vm.postAttachment.type === 'Audio')?_c('audio',{staticClass:"doc audio",attrs:{"controls":"","src":_vm.postAttachment.data}}):(_vm.postAttachment.type === 'Application' || _vm.postAttachment.type === 'Pdf')?_c('embed',{staticClass:"preview pdf-preview",attrs:{"src":_vm.postAttachment.data}}):_vm._e(),_c('div',{staticClass:"delete-cont",on:{"click":_vm.deleteAttachment}},[_vm._v(" X ")])]):_vm._e(),_c('span',{staticClass:"textarea",attrs:{"role":"textbox","contenteditable":""},on:{"input":_vm.onInput,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submitPost.apply(null, arguments)}}}),_c('div',{staticClass:"cont-btn"},[_c('div',{staticClass:"cont-count"},[_vm._v(_vm._s(_vm.countCar)+" "+_vm._s(_vm.$t("nbcar")))]),_c('div',{staticClass:"btn-green radius",on:{"click":_vm.submitPost}},[_vm._v(_vm._s(_vm.$t("btn_submit")))])])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cont-plus box-shadow"},[_c('div',{staticClass:"cont-cross"},[_c('div',{staticClass:"barcross verti"}),_c('div',{staticClass:"barcross hori"})])])}]

export { render, staticRenderFns }