<template>
<div class="elem-feed-small radius box-shadow" @click="expand">
  <div class="content-feed" ref="feedContent">
    <div class="visual-feed radius-small box-shadow"
      v-if="elem.attachments && elem.attachments.length > 0 && elem.attachments[0].type === 'Image'"
      :style="`background-image:url(${elem.attachments[0].data})`"
    >
      {{elem.attachments}}
    </div>
    <div class="video-feed radius-small box-shadow"
      v-if="elem.attachments &&
      elem.attachments.length > 0 && elem.attachments[0].type === 'Video'">
      <video
        :src="`${elem.attachments[0].data}`"
        class="preview">
      </video>
      <img class="play-video"
            :src="require('@/assets/pictos/picto_play_video.svg')">
    </div>
    <div class="video-feed radius-small box-shadow"
      v-if="elem.attachments &&
      elem.attachments.length > 0 && elem.attachments[0].type === 'Audio'">
      <audio controls="controls" :src="`${elem.attachments[0].data}`" class="doc audio"></audio>
    </div>
    <embed class="preview pdf-preview-small"
        v-if="elem.attachments && elem.attachments.length > 0 &&
        elem.attachments[0].type === 'Application' || elem.attachments[0].type === 'Pdf'"
        :src="elem.attachments[0].data"
      />
    <div class="excerp-post">
      <p class="inner-excerpt">
        {{ elem.text }}
      </p>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'feedCard',
  props: {
    elem: Object,
  },
  data() {
    return {
      expanded: false,
      isMounted: false,
    };
  },
  components: {
  },

  computed: {
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    expand() {
      this.$router.push(`/post/${this.elem._id}`);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.video-feed{
  position: relative;
  .play-video{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6vh;
    height: 6vh;
    transition: all 0.3s;
    transform: translateX(-50%) translateY(-50%);
  }
  &:hover{
    .play-video{
      opacity: 0.8;
    }
  }
}
.pdf-preview-small{
  width: 80%;
  height: 20vh;
  margin-left: 10%;
}

</style>
