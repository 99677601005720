<template>
  <div class="component-fullwidth">
    <div class="flex-container flex-full no-padding no-margin"></div>
    <div class="inner-container">
      <div class="feedview">
        <div class="inner-feedview">
          </div>
          <div class="left-feed">
            <div class="title-page wother">
              {{ $t("page_title_feed") }}
              <ToggleSwitch
                :labelOff="'All'"
                :labelOn="'Following'"
                :func="changeSource"
                :val="feedSource === 'following'"
              ></ToggleSwitch>
            </div>
            <div class="cont-newpost">
              <NewPost />
            </div>
            <div class="cont-resultspost" id="listpost">
              <my-feed />
            </div>
          </div>
          <div class="right-feed">
            <div class="title-page wother">
              <div
                class="btn-green reverse small radius-small"
                v-if="isEmployee" @click="changeView"
              >
                Switch to {{companyFeed? 'Employee Feed': 'Company Feed'}}
              </div>
              <div class="btn-green small radius-small" @click="showFollowModal">
                Follow contacts
              </div>
            </div>
            <div class="cont-right-feed radius">
              <div class="title-page wother">
                <div class="subtitle-page" v-if="tab === 'myPosts'">
                  {{ $t("myposts") }}
                  <span v-if="isEmployee">{{companyFeed? 'as Company': 'as Employee'}}</span>
                </div>
                <div class="subtitle-page" v-else>
                  {{ $t("followed") }}
                </div>
              </div>
              <div class="cont-resultsmyfeed" id="listpostbis">
                <my-posts v-if="tab === 'myPosts'" />
                <my-feed v-else />
              </div>
            </div>
          </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
// import perfectScrollInit from '@/modules/mixins/perfectScrollInit';
import PerfectScrollbar from 'perfect-scrollbar';
import ToggleSwitch from '@/components/utilityPartials/ToggleSwitch.vue';
import NewPost from '@/components/UniversalComponents/Feed/newPost.vue';
import FollowMixin from '@/components/UniversalComponents/Feed/following/openFollowModal';
import MyPosts from '../../components/UniversalComponents/Feed/myPosts.vue';
import MyFeed from '../../components/UniversalComponents/Feed/myFeed.vue';

export default {
  name: 'FeedView',
  mixins: [FollowMixin],
  data() {
    return {
      tab: 'myPosts',
    };
  },
  components: {
    NewPost,
    MyPosts,
    MyFeed,
    ToggleSwitch,
  },
  computed: {
    ...mapState('user', ['userInfo']),
    isEmployee() {
      return this.$store.state.auth.type === 'Employee';
    },
    companyFeed() {
      return this.$store.state.feed.companyView;
    },
    feedSource() {
      return this.$store.state.feed.myFeed.feedSource;
    },
  },
  mounted() {
    // perfectScrollInit('#listpost', () => this.$store.dispatch('feed/myFeed/getPosts'));
    // perfectScrollInit('#listpostbis');
    const psPost = new PerfectScrollbar('#listpost', {
      wheelPropagation: false,
      wheelSpeed: 1,
    });
    psPost.update();
    const psPostBis = new PerfectScrollbar('#listpostbis', {
      wheelPropagation: false,
      wheelSpeed: 1,
    });
    psPostBis.update();
    if (this.$store.getters['feed/myFeed/loaded']) {
      this.$store.dispatch('feed/myFeed/getPosts');
    } else {
      this.$store.dispatch('feed/myFeed/getInitialPosts');
    }
  },
  methods: {
    handleClick() {
      if (this.tab === 'feedPosts') {
        this.tab = 'myPosts';
      } else {
        this.tab = 'feedPosts';
      }
    },
    changeView() {
      this.$store.dispatch('feed/switchView');
      this.goTop();
    },
    changeSource() {
      this.$store.dispatch('feed/myFeed/changeFeedSource').then(() => {
        this.goTop();
      });
    },
    goTop() {
      const element = document.getElementById('listpost');
      this.$nextTick(() => {
        element.scrollTop = 0;
      });
    },
  },

};
</script>
<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .feedview{
    width: 100%;
    display: flex;
      .left-feed{
        width: calc(65% - 4vw);
        margin-right: 4vw;
        display: flex;
        flex-direction: column;
        .title-page{
          width: 100%;
        }
        .cont-newpost{
          width: 100%;
        }
        .cont-resultspost{
          position: relative;
          width: 100%;
          max-height: 70vh;
          overflow: hidden;
        }
      }
      .right-feed{
        width: 35%;
        display: flex;
        flex-direction: column;
        .btn-green{
          margin: 0.45vh 0 0.4vh 0;
        }
        .cont-right-feed{
          background-color: $new_bg_grey;
          padding: 1vh;
          height: 76.9vh;
          .title-page{
            width: 100%;
          }
          .cont-resultsmyfeed{
            width: calc(100% - 0vh);
            position: relative;
            height: 74.5vh;
            overflow: hidden;
          }
        }
      }
  }
</style>

<style lang="scss">
.left-feed{
  .inner-post-entry {
    .elem-feed{
      .content-feed{
        .visual-feed{
          max-height: 35vh;
          video{
            max-height: 35vh;
            background-color: #000000;
          }
        }
      }
    }
  }
}
</style>
