<template>
  <div class="inner-feed-entry">
    <feedCardSmall v-for="entry in myPosts" :key="entry._id" :elem="entry" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import feedCardSmall from '@/components/UniversalComponents/Search/Results/specificCards/feedCardSmall.vue';

export default {
  name: 'myPostsView',
  components: {
    feedCardSmall,
  },
  data() {
    return {
      postText: '',
    };
  },
  computed: {
    ...mapState('feed/myPosts', ['myPosts']),
  },
  mounted() {
    this.$store.dispatch('feed/myPosts/updateMyPosts');
  },
  methods: {
  },

};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
   .inner-feed-entry{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    .elem-feed-small{
      width: calc(calc(100% - 1.5vh) / 2);
      margin-right: 1.5vh;
      margin-bottom: 1.5vh;
      flex: 0 auto;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      cursor: pointer;
      height: 20.5vh;
      overflow: hidden;
      &:nth-child(2n){
        margin-right: 0;
      }
      .content-feed{
        padding: 1vh;
        flex: 1;
        .excerp-post{
          margin-bottom: 1vh;
          max-height: 150px;
          overflow: hidden;
          font-size: 1.4vh;
        }
        .visual-feed{
          width: 100%;
          height: 10vh;
          background-size: cover;
          background-position: 50% 50%;
          font-size: 0;
          overflow: hidden;
          margin-bottom: 1vh;
        }
        .video-feed{
          width: 100%;
          height: 16vh;
          font-size: 0;
          overflow: hidden;
          margin-bottom: 1vh;
          video{
            height: 100%;
            width: 100%;
            background-color: $text_color;
          }
        }
      }
    }
  }
</style>
